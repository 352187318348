import "./style.scss";

import { graphql } from "gatsby";
import { useTranslation } from "react-i18next/dist/es/useTranslation";
import {
  heading,
  paragraph,
  renderCom,
} from "../../../components/ForMarkDownComponents";

import Container from "../../../components/Container";
import EmailForm from "../../../components/Forms/_email";
import BreadcrumbsBlock from "../../../components/Breadcrumbs/BreadcrumbsBlock";
import Layout from "../../../layouts";
import { createClassName } from "../../../helpers";
import supportCom from "../../../components/ForMarkDownComponents/_support";

const cn = createClassName("RemainderAndDownload");

const DownloadVersion = ({ data }) => {
  const { t } = useTranslation("forms");
  const { htmlAst, frontmatter } = data.markdownRemark;
  return (
    <Layout {...frontmatter.seo[0]}>
      <section aria-label="download your version">
        <BreadcrumbsBlock frontmatter={frontmatter} />
        <Container className={cn("wrap")}>
          {supportCom(htmlAst)}
          <EmailForm
            apiLink="licenses/remind"
            classNameForm={cn("form", { wrap: true })}
            fieldClassName={cn("form", { field: true })}
            buttonTextKey="send_me_link"
            tooltipContent={t("forms:tooltip.download-version")}
            errorHeaderTitle={t("defaultText.errorTitle")}
            successHeaderTitle={t("defaultText.successHeaderTitle")}
            tryAgain
          />
        </Container>
      </section>
    </Layout>
  );
};

export default DownloadVersion;

export const query = graphql`
  query getDownloadVersion($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        seo {
          description
          link
          title
        }
        path
        bannerTitle
        bannerSubTitle
      }
    }
  }
`;
